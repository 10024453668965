import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Layout, Container } from 'layouts';
//import { Header } from 'components';
import config from '../../config/site';
import { graphql } from 'gatsby';
//import MDXRenderer from 'gatsby-mdx/mdx-renderer';
//import Highlight, { defaultProps } from 'prism-react-renderer';
//import Code from 'components/Code';
import Prism from 'prismjs';
require('prismjs/themes/prism-okaidia.css');

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.white.light};
  padding: 5px 10px;
  border: solid 1px #fff;
  border-radius: 20px;
  &:hover {
    color: ${props => props.theme.colors.black.blue};
    background: ${props => props.theme.colors.white.light};
  }
`;
const Header = styled.div`
display: flex;
justify-content:center;
text-align: center;
padding: 5% 0 2% 0;
//background: ${props => props.theme.colors.black.blue};
font-size: 2rem;
@media (max-width: 600px) {
  padding-top: 20%;
}
`;
const Information = styled.div`
  text-align: center;

  .prism-code {
    padding: 0.75rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-size: 16px;
  }
  h1 {
    font-size: 2rem;
    margin-bottom: 1.25rem;
  }
`;

const Tag = ({ data }) => {
  //const { posts, tagName } = pageContext;
  //const upperTag = tagName.charAt(0).toUpperCase() + tagName.slice(1);
  const post = data.markdownRemark;
  const postM = data.markdownRemark.html;
  return (
    <Layout>
      <Helmet title={`${post.frontmatter.title}  |  Limitless Unity Development`} />
      <Header style={{ background: post.backgroundCol }}>
        {post.frontmatter.title}
      </Header>
      <Container>
        <Information>
          <div style = {{width:"80vw", left: "20vw"}} dangerouslySetInnerHTML={{ __html: postM }} />
        </Information>
      </Container>
    </Layout>
  );
};

export default Tag;

Tag.propTypes = {
  pageContext: PropTypes.shape({

    //posts: PropTypes.array,
    //tagname: PropTypes.string,
  }),
  data: PropTypes.object.isRequired,
};

export const query = graphql`
query($pathSlug: String!) {
  markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
html
          frontmatter {
            path
            date
            title
            backgroundCol
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 1000
                  quality: 90
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
`;
